<!-- eslint-disable import/no-unresolved -->
<template>
  <div>
    <validation-observer ref="generalTab">
      <b-card id="personalInfo-section">
        <b-form>
          <h4 class="mb-2">
            {{ $t('residency_introduction') }}
          </h4>
          <b-row>
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                label-for="residency-country"
                :label="$t('residency_country')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('residency_country')"
                  vid="residency-country"
                  rules="required"
                >
                  <v-select
                    id="residency-country"
                    v-model="userProfile.residency"
                    :dir="!$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="formsListData.countries"
                    :disabled="!$store.state.profile.profileInfo.edit_profile"
                    :placeholder="$t('residency_country')"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                label-for="citizenship-country"
                :label="$t('citizenship_country')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('citizenship_country')"
                  vid="citizenship-country"
                  rules="required"
                >
                  <v-select
                    id="citizenship-country"
                    v-model="userProfile.citizenship"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :disabled="!$store.state.profile.profileInfo.edit_profile"
                    :options="formsListData.countries"
                    :placeholder="$t('citizenship_country')"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                :label="$t('city')"
                label-for="city"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('city')"
                  vid="city"
                  rules="required"
                >
                  <b-form-input
                    v-model="userProfile.city"
                    :disabled="!$store.state.profile.profileInfo.edit_profile"
                    :placeholder="$t('city')"
                    name="city"
                    @keypress="onlyAlpha($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>

        <!-- allProfileInfo.form -->
        <b-form class="mt-2">
          <h4 class="mb-2">
            {{ $t('personal_information') }}
          </h4>
          <b-row>
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                :label="$t('first_name')"
                label-for="FirstName"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('first_name')"
                  vid="first_name"
                  rules="required"
                >
                  <b-form-input
                    v-model="userProfile.first_name"
                    :placeholder="$t('first_name')"
                    name="FirstName"
                    :disabled="!$store.state.profile.profileInfo.edit_profile"
                    autocomplete="nope"
                    @keypress="onlyAlpha($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--/ First Name -->
            <!-- Last Name -->
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                :label="$t('last_name')"
                label-for="LastName"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('last_name')"
                  vid="last_name"
                  rules="required"
                >
                  <b-form-input
                    v-model="userProfile.last_name"
                    name="LastName"
                    :disabled="!$store.state.profile.profileInfo.edit_profile"
                    :placeholder="$t('last_name')"
                    autocomplete="nope"
                    @keypress="onlyAlpha($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!--/ First Name -->
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                label-for="example-datepicker"
                :label="$t('birth_date')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('birth_date')"
                  vid="birth_date"
                  rules="required"
                >
                  <date-picker
                    id="birth_date"
                    v-model="convertedBirthdate"
                    :color="primaryColor"
                    :locale="citizenshipName === 'Iran' ? 'fa' : 'en'"
                    type="date"
                    :disabled="!$store.state.profile.profileInfo.edit_profile || !citizenshipName"
                    :placeholder="$t('birth_date')"
                    auto-submit
                  />
                  <!-- citizenshipName === 'Iran' ?  -->
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                label-for="gender"
                :label="$t('gender')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('gender')"
                  vid="Gender"
                  rules="required"
                >
                  <v-select
                    id="v"
                    v-model="userProfile.profile.gender"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    label="value"
                    :options="formsListData.gender"
                    :disabled="!$store.state.profile.profileInfo.edit_profile"
                    :placeholder="$t('gender')"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Email -->
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                :label="$t('email')"
                label-for="account-e-mail"
              >
                <b-form-input
                  v-model="userProfile.email"
                  name="email"
                  :placeholder="$t('email')"
                  :disabled="true"
                  autocomplete="nope"
                />

              </b-form-group>
            </b-col>
            <!--/ Email -->
            <!--  Gender -->

            <!--/  Gender -->
            <!-- Phone Country Code -->
            <!-- <b-col
                sm="12"
                xl="6"
                class="col-xxl-4"
              >
                <b-form-group
                  label-for="phone-country-code"
                  :label="$t('phone_country_code')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('phone_country_code')"
                    vid="phone_country_code"
                    rules="required"
                  >
                    <v-select
                      id="phone-country-code"
                      v-model="userProfile.phone_country_code"
                      label="precode"
                      :filter="codeSearch"
                      :options="formsListData.pre_country_code"
                      :placeholder="$t('phone_country_code')"
                      autocomplete="nope"
                      :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    >
                      <template
                        slot="option"
                        slot-scope="option"
                        class="w-100 text-left"
                      >
                        <div
                          dir="ltr"
                          class="w-100 d-flex "
                        >
                          <img
                            :src="option.image"
                            class="language-image"
                          >
                      &nbsp;
                          <span class="select d-block">+{{ option.precode }}</span>
                          <span class=" d-block">({{ option.name }})</span>
                        </div>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                </b-col> -->
            <!--/ Phone Country Code -->
            <!-- phone Number -->
            <!-- <b-col
                sm="12"
                xl="6"
                class="col-xxl-4"
              >
                <b-form-group
                  label-for="phone-number"
                  :label="$t('phone_number')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('phone_number')"
                    vid="phone"
                    rules="required"
                  >
                    <b-form-input
                      id="phone-number"
                      v-model="userProfile.phone"
                      class="form-control"
                      :placeholder="$t('phone_number')"
                      autocomplete="nope"
                      minlength="3"
                      maxlength="15"
                      @keypress="onlyNumbers($event)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                </b-col> -->
            <!-- phone Number -->
            <!-- birth date -->

            <b-col cols="12">
              <b-button
                v-if="!pending"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                :disabled="!$store.state.profile.profileInfo.edit_profile"
                @click="submit"
              >
                {{ $t('save_changes') }}
              </b-button>

              <b-button
                v-if="pending"
                variant="primary"
                class="mt-2 mr-1"
                disabled
              >
                <b-spinner
                  small
                  type="grow"
                />
                <span>{{ $t('loading') }}</span>
              </b-button>
            </b-col>
            <!--/ birth date -->
          </b-row>
        </b-form>
      </b-card>

    <!-- <b-card :title="$t('skill_profession')">
        <b-form class="mt-2">
          <b-row>
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                label-for="experience"
                :label="$t('experience')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('experience')"
                  vid="Experience"
                  rules="required"
                >
                  <v-select
                    id="experience"
                    v-model="userProfile.profile.experience"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    label="value"
                    :options="formsListData.experience"
                    :placeholder="$t('experience')"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                :label="$t('profession')"
                label-for="profession"
              >
                <b-form-input
                  v-model="userProfile.profile.profession"
                  name="profession"
                  :placeholder="$t('profession')"
                  autocomplete="nope"
                  maxlength="50"
                  @keypress="onlyAlpha($event)"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                label-for="hear"
                :label="$t('hear')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('hear')"
                  vid="Hear"
                  rules="required"
                >
                  <v-select
                    id="hear"
                    v-model="userProfile.profile.hear"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    label="value"
                    :options="formsListData.hear"
                    autocomplete="nope"
                    :placeholder="$t('hear')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                :label="$t('hear_description')"
                label-for="hear-description"
              >
                <b-form-input
                  v-model="userProfile.profile.hear_desc"
                  name="hear-description"
                  :placeholder="$t('hear_description')"
                  autocomplete="nope"
                />
              </b-form-group>
            </b-col>

            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                label-for="trade-style"
                :label="$t('trade_style')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('trade_style')"
                  vid="Trade Style"
                  rules="required"
                >
                  <v-select
                    id="trade-style"
                    v-model="userProfile.profile.trade_style"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    label="value"
                    :options="formsListData.trade_style"
                    autocomplete="nope"
                    :placeholder="$t('trade_style')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                label-for="trade-assets"
                :label="$t('trade_assets')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('trade_assets')"
                  vid="Trade Assets"
                  rules="required"
                >
                  <v-select
                    id="trade-assets"
                    v-model="userProfile.profile.trade_asset"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    label="value"
                    :options="formsListData.trade_style"
                    autocomplete="nope"
                    :placeholder="$t('trade_assets')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              v-if="userProfile.isActive === 0"
              cols="12"
              class="mt-75"
            >
              <b-alert
                show
                variant="warning"
                class="mb-50"
              >
                <h4 class="alert-heading">
                  Your email is not confirmed. Please check your inbox.
                </h4>
                <div class="alert-body">
                  <b-link
                    class="alert-link"
                    @click="resendMail"
                  >
                    Resend confirmation
                  </b-link>
                </div>
              </b-alert>
            </b-col>
          </b-row>
        </b-form>
        </b-card> -->
    </validation-observer>
    <ValidationObserver ref="mobileActivationForm">
      <b-card :title="$t('mobile_number')">
        <b-form class="mt-2">

          <p style="margin-right: 3px;">
            {{ $t('guide') }} : {{ $t('guide-desc') }}
          </p>

          <b-row>

            <b-col
              sm="12"
              xl="4"
              class="col-xxl-4"
            >

              <b-form-group
                label-for="mobile-country-code"
                :label="$t('mobile_country_code')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('mobile_country_code')"
                  vid="mobile_country_code"
                  rules="required"
                >
                  <v-select
                    id="mobile-country-code"
                    v-model="mobile_country_code"
                    label="precode"
                    :filter="codeSearch"
                    :options="formsListData.pre_country_code"
                    :placeholder="$t('mobile_country_code')"
                    autocomplete="nope"
                    :disabled="$store.state.profile.profileInfo.mobile_is_verify"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                  >
                    <template
                      slot="option"
                      slot-scope="option"
                      class="w-100 text-left"
                    >
                      <div
                        dir="ltr"
                        class="w-100 d-flex "
                      >
                        <img
                          :src="option.image"
                          class="language-image"
                        >
                        &nbsp;
                        <span class="select d-block">+{{ option.precode }}</span>
                        <span class=" d-block">({{ option.name }})</span>
                      </div>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

            </b-col>
            <b-col
              sm="12"
              xl="4"
              class="col-xxl-4"
            >
              <b-form-group
                label-for="mobile-number"
                :label="$t('mobile_number')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('mobile_number')"
                  vid="phone"
                  rules="required|noZeroStater"
                >
                  <b-form-input
                    id="mobile-number"
                    v-model="mobile_number"
                    class="form-control"
                    :placeholder="$t('mobile_number')"
                    autocomplete="nope"
                    minlength="3"
                    maxlength="15"
                    :disabled="$store.state.profile.profileInfo.mobile_is_verify"
                    @keypress="onlyNumbers($event)"
                  />
                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-button
                v-if="!codePending"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                :disabled="$store.state.profile.profileInfo.mobile_is_verify"
                @click="SendCode"
              >
                {{ $t('send_code') }}
              </b-button>

              <b-button
                v-if="codePending"
                variant="primary"
                class="mt-2 mr-1"
                disabled
              >
                <b-spinner
                  small
                  type="grow"
                />
                <span>{{ $t('loading') }}</span>
              </b-button>
            </b-col>
          </b-row>
          <b-row
            v-if="($store.state.profile.profileInfo.mobile && !$store.state.profile.profileInfo.mobile_is_verify) || show"
            class="d-flex justify-content-center mt-3 mt-xl-0"
          >
            <b-col
              sm="12"
              xl="4"
              class="col-xxl-4"
            >
              <b-form-group
                label-for="receive-code"
                :label="$t('receive_code')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('receive_code')"
                  vid="receive-code"
                  rules="required"
                >
                  <b-form-input
                    id="receive-code"
                    v-model="receive_code"
                    class="form-control"
                    :placeholder="$t('receive_code_placeholder')"
                    autocomplete="nope"
                    minlength="3"
                    maxlength="15"
                    @keypress="onlyNumbers($event)"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

            </b-col>
            <b-col
              sm="12"
              xl="4"
              class="col-xxl-4"
            >
              <b-button
                v-if="!registerPending"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                @click="RegisterCode"
              >
                {{ $t('code_registration') }}
              </b-button>

              <b-button
                v-if="registerPending"
                variant="primary"
                class="mt-2 mr-1"
                disabled
              >
                <b-spinner
                  small
                  type="grow"
                />
                <span>{{ $t('loading') }}</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </ValidationObserver>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BSpinner,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required, noZeroStater } from '@validations'
import { ValidationObserver } from 'vee-validate'
import jalaali from 'moment-jalaali'
import DatePicker from 'vue-persian-datetime-picker'
import moment from '@/libs/moment'
import { alphanumeric } from '@/mixins/alphanumeric'
import ProfileApis from '@/services/apis/profile'
import FormApis from '@/services/apis/form'
import { toEnglishDigits } from '@/utils'
// import tour from '@/mixins/tour'
// import tour from '@/mixins/tour'
const formData = new FormApis()
const userProfile = new ProfileApis()
export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BSpinner,
    vSelect,
    ValidationObserver,
    DatePicker,
  },
  directives: {
    Ripple,
  },
  mixins: [alphanumeric],
  props: {
    profileInfoP: {
      type: Object,
      default: () => ({

      }),
    },
  },
  data() {
    return {
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
      required,
      noZeroStater,
      show: false,
      // disabledNumber: false,
      // disabledCountryCode: false,
      // disabledSendCode: false,
      pending: false,
      codePending: false,
      registerPending: '',
      receive_code: '',
      // phone_country_code: '',
      mobile_country_code: '',
      mobile_number: '',
      countResidency: 1,
      countCitizenship: 1,
      residency: '',
      citizenship: '',
      userProfile: {
        profile: {},
      },
      formsListData: {
        gender: [],
        // trade_style: [],
        trade_asset: [],
        // experience: [],
        // hear: [],
        countries: [],
        pre_country_code: [],
        questions: [],
      },
      flatPickerConfig: {
        maxDate: moment.utc().local().format('YYYY-MM-DD HH:mm:ss'),
      },
    }
  },

  computed: {
    convertedBirthdate: {
      get() {
        const date = this.userProfile?.profile?.birth_date

        if (date && this.userProfile.citizenship) {
          const newDate = date.replaceAll('-', '/')
          return jalaali(newDate).format('jYYYY/jM/jD')
        }
        return null
      },

      set(value) {
        const val = jalaali(value, 'jYYYY/jMM/jDD').format('YYYY-MM-DD')
        this.userProfile.profile.birth_date = val
      },
    },
    primaryColor() {
      return process.env.VUE_APP_PRIMARY_COLOR
    },
    citizenshipName() {
      return this.userProfile?.citizenship?.name
    },
  },

  watch: {
    async profileInfoP(val) {
      if (val) {
        this.userProfile = val
        this.mobile_number = val.mobile
        this.mobile_country_code = { precode: val.mobile_country_code }
        // if (!val.citizenship?.id || !val.residency?.id) {
        //   await this.tour.addStep({
        //     id: 'residencySection1',
        //     text: this.$t('tour_residency_message'),
        //     attachTo: {
        //       element: '#residency-section',
        //       on: 'bottom',
        //     },
        //     buttons: [
        //       {
        //         text: this.$t('tour_ok_buton'),
        //         action: this.tour.next,
        //       },
        //     ],
        //   })
        // }
        // if (!val.last_name || !val.first_name) {
        //   await this.tour.addStep({
        //     id: 'residencySection2',
        //     text: this.$t('tour_personalInfo_message'),
        //     attachTo: {
        //       element: '#personalInfo-section',
        //       on: 'bottom',
        //     },
        //     buttons: [
        //       {
        //         text: this.$t('tour_ok_buton'),
        //         action: this.tour.next,
        //       },
        //     ],
        //   })
        // }

        // if (this.tour.steps.length > 0) {
        //   this.tour.start()
        // }
      }
    },
  },
  mounted() {
    formData.allproperties().then(res => {
      const result = res.data.results
      this.formsListData = result
      this.formsListData.pre_country_code = result.countries.map(item => ({
        // eslint-disable-next-line global-require, import/no-unresolved, import/no-dynamic-require
        precode: item.precode, id: item.id, name: item.name, image: require(`@/assets/images/flags/${item.abbreviation}.png`),
      }))
    })
  },
  methods: {

    // ...mapActions(['saveGeneral']),
    async submit() {
      const success = await this.$refs.generalTab.validate()
      if (success) {
        this.pending = true
        const precode = this.getPrecode()
        const data = this.getData(precode)
        userProfile.saveGeneral(data).then(res => {
          this.$swal({
            title: res.data.message,
            icon: 'success',
            timer: 2500,
            showConfirmButton: false,
          })
          if (res.status === 201) {
            this.$emit('update:active', 16)
          }
          userProfile.get().then(() => {
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth',
            })
          })
          this.pending = false
        }).catch(() => {
          this.pending = false
          // window.scroll({
          //   top: 0,
          //   left: 0,
          //   behavior: 'smooth',
          // })
        })
      }
    },

    // async resendMail() {
    //   const response = await userProfile()
    //   this.showReaction(response)
    // },
    getPrecode() {
      let precode = ''
      const code = this.userProfile.phone_country_code
      if (code && code.precode !== undefined) {
        precode = code.precode.replace('+', '')
      } else {
        // precode = code.replace('+', '')
      }
      return precode
    },
    getData(precode) {
      return {
        residency_id: this.userProfile.residency.id,
        citizenship_id: this.userProfile.citizenship.id,
        city: this.userProfile.city,
        first_name: this.userProfile.first_name,
        last_name: this.userProfile.last_name,
        // phone_country_code: precode,
        // phone: this.userProfile.phone,

        // experience_id: this.userProfile.profile.experience.id,
        gender_id: this.userProfile.profile.gender.id,
        // hear_id: this.userProfile.profile.hear.id,
        // hear_desc: this.userProfile.profile.hear_desc,
        birth_date: this.userProfile.profile.birth_date,
        // profession: this.userProfile.profile.profession,
        // trade_style: this.userProfile.profile.trade_style.id,
        // trade_asset: this.userProfile.profile.trade_asset.id,
      }
    },
    // checkResidency() {
    //   if (this.countResidency === 1 && Object.keys(this.allProfileInfo.form).length !== 0) {
    //     this.residency = this.userProfile.residency
    //     this.countResidency = 2
    //     return this.residency !== null
    //   }
    //   return this.residency !== null
    // },
    checkCitizenship() {
      if (this.countCitizenship === 1 && Object.keys(this.allProfileInfo.form).length !== 0) {
        this.citizenship = this.allProfileInfo.form.citizenship
        this.countCitizenship = 2
        return this.citizenship !== null
      }
      return this.citizenship !== null
    },

    codeSearch(options, search) {
      return options.filter(element => element.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 || element.precode.indexOf(search) !== -1)
    },
    async SendCode() {
      this.codePending = true
      const data = { mobile: toEnglishDigits(this.mobile_number), mobile_country_code: this.mobile_country_code ? toEnglishDigits(this.mobile_country_code?.precode) : undefined }
      await userProfile.acceptMobile(data).then(res => {
        this.$swal({
          title: res.data.message,
          icon: 'success',
          timer: 2500,
          showConfirmButton: false,
        })
        userProfile.get()
        this.show = true
        this.codePending = false
      }).catch(() => {
        this.codePending = false
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      })
    },
    async RegisterCode() {
      this.registerPending = true
      const data = { code: toEnglishDigits(this.receive_code) }
      await userProfile.verifyMobile(data).then(res => {
        this.$swal({
          title: res.data.message,
          icon: 'success',
          timer: 2500,
          showConfirmButton: false,

        })
        userProfile.get()
        this.registerPending = false
        this.show = false
        // this.disabledNumber = true
        // this.disabledCountryCode = true
        // this.disabledSendCode = true
      }).finally(() => {
        this.registerPending = false
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      })
    },
  },

}
</script>
<style>
.select {
  margin-right: 5px;
}
</style>
