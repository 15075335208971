<template>
  <div>

    <b-overlay
      :show="show"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div
        class="mb-2 rounded"
        :class="$store.state.appConfig.layout.skin === 'dark' ? 'tab-bg' :'bg-white'"
      >
        <b-tabs
          v-model="tabIndex"
          :class="$store.state.appConfig.layout.skin === 'dark' ? 'custom-tab-bg-blue' :'custom-tab-bg-aliceblue'"
          content-class="col-12 col-md-12"
          pills
          nav-wrapper-class="col-md-12 col-12"
          class="row profile-tab"
        >

          <b-tab active>
            <template
              #title
            >
              <div :class="$store.state.appConfig.layout.skin === 'dark' ? 'text-white' :''">
                <feather-icon
                  icon="UserIcon"
                  size="14"
                  class="mx-0 px-0"
                />
                <span class="font-weight-bold">{{ $t("general") }}</span>
              </div>
            </template>
          </b-tab>

          <!--/ general tab -->
          <b-tab>
            <template #title>
              <div :class="$store.state.appConfig.layout.skin === 'dark' ? 'text-white' :''">
                <feather-icon
                  icon="LockIcon"
                  size="14"
                  class="mx-0 px-0"
                />
                <span class="font-weight-bold">{{ $t('security') }}</span>
              </div>
            </template>
          </b-tab>
          <b-tab>
            <!-- title -->
            <template #title>
              <div :class="$store.state.appConfig.layout.skin === 'dark' ? 'text-white' :''">
                <feather-icon
                  icon="LinkIcon"
                  size="14"
                  class="mx-0 px-0"
                />
                <span class="font-weight-bold">{{ $t('notification') }}</span>
              </div>
            </template>
          </b-tab>
        <!-- <b-tab :disabled="$store.state.profile.profileInfo==0|| foreignActivation">
          <template #title>
            <feather-icon
              icon="LinkIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Foreign</span>
          </template>
          <foreign />
        </b-tab> -->
        </b-tabs>
      </div>
      <general
        v-show="tabIndex==0"
        :profile-info-p="$store.state.profile.profileInfo"
      />
      <security
        v-show="tabIndex==1"
        :profile-info="$store.state.profile.profileInfo"
      />
      <notification
        v-show="tabIndex==2"
        :profile-notification="$store.state.profile.profileInfo.notification"
      />

    </b-overlay>
  </div>
</template>
<script>
import {
  BTabs, BTab, BOverlay,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import General from './General.vue'
import Security from './Security.vue'
import Notification from './Notification.vue'
// import Foreign from './Foreign.vue'
import ProfileApis from '@/services/apis/profile'
import tabsMacher from '@/mixins/tabsMacher2'

const userProfile = new ProfileApis()

export default {
  components: {
    BTabs,
    BTab,
    BOverlay,
    General,
    Security,
    Notification,
    // Foreign,
  },
  mixins: [tabsMacher],
  data() {
    return {
      show: true,
    }
  },

  computed: {
    ...mapGetters(['getCitizenship']),
    foreignActivation() {
      return this.getCitizenship === 'IR'
    },
  },
  mounted() {},
  async created() {
    await userProfile.get()
    this.show = false
  },
}
</script>

<style  lang="scss" >
.custom-tab-bg-aliceblue .nav-pills .nav-link.active{
  background-color: $primary!important;
}
.custom-tab-bg-aliceblue .nav-pills .nav-link{
  background-color: aliceblue  ;
}
.custom-tab-bg-blue .nav-pills .nav-link{
  background-color: rgb(155, 190, 221);
}
[dir] .tabs .nav-pills .nav-link{
  margin: 3px !important;
}
// .tab-bg{
//   background-color: #172238 ;
// }
</style>
<!-- @import '@core/scss/vue/libs/vue-select.scss'; -->
